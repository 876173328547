import React, { useRef } from "react";

import { GAME_SHORT_NAMES } from "@/app/constants.mjs";
import { LinkSurveys } from "@/feature-survey/constants.mjs";
import Survey from "@/feature-survey/Survey.jsx";
import SurveyLink from "@/feature-survey/SurveyLink.jsx";
import { useGameSymbol } from "@/util/game-route.mjs";
import { useRoute } from "@/util/router-hooks.mjs";

function SurveyWrapper() {
  const route = useRoute({ isGlobal: true });
  const surveyIsOpen = useRef(false);

  const gameSymbol = useGameSymbol();
  const gameShortName = GAME_SHORT_NAMES[gameSymbol];

  const isPostmatch = route?.searchParams.get("postmatch") ?? false;

  if (isPostmatch) {
    // Prevent survey from closing on postmatch route while route loads.
    // Also drastically reduces the number or rerenders.
    surveyIsOpen.current = true;
  }

  if (isPostmatch || surveyIsOpen.current) {
    if (
      LinkSurveys.length > 0 &&
      LinkSurveys.some(
        (survey) =>
          survey.gameTypes.includes(gameShortName) &&
          new Date() < survey.endDate &&
          localStorage.getItem(`linkedSurveyDismissed_${survey.key}`) !==
            "true",
      )
    ) {
      return (
        <SurveyLink
          surveyIsOpen={surveyIsOpen}
          surveyData={LinkSurveys.find(
            (s) =>
              s.gameTypes.includes(gameShortName) && new Date() < s.endDate,
          )}
        />
      );
    }
    return <Survey surveyIsOpen={surveyIsOpen} />;
  }
  return null;
}

export default SurveyWrapper;

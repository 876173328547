import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "clutch/src/Button/Button.jsx";

import {
  __ONLY_WRITE_STATE_FROM_ACTIONS as writeState,
  readState,
} from "@/__main__/app-state.mjs";
import { GAME_SHORT_NAMES } from "@/app/constants.mjs";
import { TimesDismissedUntilHidden } from "@/feature-survey/constants.mjs";
import {
  CloseButton,
  LinkSurveyContainer,
  LinkSurveyContent,
  SurveyText,
} from "@/feature-survey/Survey.style.jsx";
import BlitzCloseIcon from "@/inline-assets/close-icon.svg";
import Message from "@/inline-assets/message.svg";
import { useGameSymbol } from "@/util/game-route.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

function SurveyLink({
  surveyIsOpen,
  surveyData,
}: {
  surveyIsOpen: React.MutableRefObject<boolean>;
  surveyData?: {
    key: string;
    primaryMessage: string[];
    secondaryMessage: string[];
    surveyLink: string;
    gameTypes: string[];
    endDate: Date;
  };
}) {
  const gameSymbol = useGameSymbol();
  const { t } = useTranslation();
  const { settings } = useSnapshot(readState);

  const {
    key,
    primaryMessage,
    secondaryMessage,
    surveyLink,
    gameTypes,
    endDate,
  } = surveyData;

  const [isDismissed, setIsDismissed] = useState(false);

  const gameShortName = GAME_SHORT_NAMES[gameSymbol];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { isSurveyDisabled } = (settings["survey"] || {}) as any;

  const handlePreSubmitCloseButton = useCallback(() => {
    const dismissedCount = settings?.survey?.linkedSurveyDismissedCount || 0;
    const newDimissedCount = dismissedCount + 1;
    writeState.settings.survey.linkedSurveyDismissedCount = newDimissedCount;
    if (newDimissedCount >= TimesDismissedUntilHidden) {
      localStorage.setItem(`linkedSurveyDismissed_${key}`, "true");
    }
    setIsDismissed(true);
    surveyIsOpen.current = false;
  }, [key, settings?.survey?.linkedSurveyDismissedCount, surveyIsOpen]);

  if (
    isDismissed ||
    isSurveyDisabled ||
    !gameTypes.includes(gameShortName) ||
    new Date() > endDate
  ) {
    return;
  }

  return (
    <LinkSurveyContainer>
      <LinkSurveyContent>
        <SurveyText className="type-body2-form">
          <Message />
          <div>
            <div className="type-form--button">{t(primaryMessage)}</div>
            <div className="type-caption shade1">{t(secondaryMessage)}</div>
          </div>
        </SurveyText>
        <Button
          emphasis="high"
          href={surveyLink}
          target="_blank"
          className="desktop-only"
          onClick={handlePreSubmitCloseButton}
        >
          {t("common:takeSurvey", "Take Survey")}
        </Button>
      </LinkSurveyContent>
      <CloseButton
        type="submit"
        role="button"
        onClick={handlePreSubmitCloseButton}
      >
        <BlitzCloseIcon />
      </CloseButton>
    </LinkSurveyContainer>
  );
}

export default SurveyLink;
